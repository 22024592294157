import React, { useState } from 'react';
import { Bell, Plus, Trash2, Check } from 'lucide-react';

const employees = [
  "John Doe", "Jane Smith", "Mike Johnson", "Emily Brown", "Chris Lee"
];

const ReminderView = () => {
  const [reminders, setReminders] = useState([]);
  const [newReminder, setNewReminder] = useState({ employeeNames: [], task: '', dueDate: '' });
  const [isEmployeeDropdownOpen, setIsEmployeeDropdownOpen] = useState(false);

  const addReminder = () => {
    if (newReminder.employeeNames.length > 0 && newReminder.task && newReminder.dueDate) {
      setReminders([...reminders, { ...newReminder, id: Date.now() }]);
      setNewReminder({ employeeNames: [], task: '', dueDate: '' });
    }
  };

  const deleteReminder = (id) => {
    setReminders(reminders.filter(reminder => reminder.id !== id));
  };

  const toggleEmployee = (employee) => {
    setNewReminder(prev => ({
      ...prev,
      employeeNames: prev.employeeNames.includes(employee)
        ? prev.employeeNames.filter(e => e !== employee)
        : [...prev.employeeNames, employee]
    }));
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mb-6 bg-white p-4 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">Agregar nuevo recordatorio</h2>
        <div className="flex flex-wrap -mx-2">
          <div className="relative flex-grow m-2">
            <button
              onClick={() => setIsEmployeeDropdownOpen(!isEmployeeDropdownOpen)}
              className="w-full p-2 border rounded text-left bg-white"
            >
              {newReminder.employeeNames.length > 0
                ? `${newReminder.employeeNames.length} employee(s) selected`
                : 'Select Employees'}
            </button>
            {isEmployeeDropdownOpen && (
              <div className="absolute z-10 w-full mt-1 bg-white border rounded shadow-lg">
                {employees.map(employee => (
                  <label key={employee} className="flex items-center p-2 hover:bg-gray-100">
                    <input
                      type="checkbox"
                      checked={newReminder.employeeNames.includes(employee)}
                      onChange={() => toggleEmployee(employee)}
                      className="mr-2"
                    />
                    {employee}
                    {newReminder.employeeNames.includes(employee) && (
                      <Check size={16} className="ml-auto text-green-500" />
                    )}
                  </label>
                ))}
              </div>
            )}
          </div>
          <input
            type="text"
            placeholder="Task"
            className="flex-grow m-2 p-2 border rounded"
            value={newReminder.task}
            onChange={(e) => setNewReminder({ ...newReminder, task: e.target.value })}
          />
          <input
            type="date"
            className="flex-grow m-2 p-2 border rounded"
            value={newReminder.dueDate}
            onChange={(e) => setNewReminder({ ...newReminder, dueDate: e.target.value })}
          />
          <button
            onClick={addReminder}
            className="flex-grow m-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
          >
            <Plus className="inline-block mr-2" size={18} /> Add Reminder
          </button>
        </div>
      </div>
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employees</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Task</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Due Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {reminders.map((reminder) => (
              <tr key={reminder.id}>
                <td className="px-6 py-4 whitespace-nowrap">{reminder.employeeNames.join(", ")}</td>
                <td className="px-6 py-4 whitespace-nowrap">{reminder.task}</td>
                <td className="px-6 py-4 whitespace-nowrap">{reminder.dueDate}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => deleteReminder(reminder.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReminderView;