// components/Header.js

import React, { useState, useEffect } from 'react'
import { Star, Clock, Users, Trash2, Edit2, Check, Download } from 'lucide-react'
import Logo from '../../img/LogoMiido.png'
import * as XLSX from 'xlsx';

const url = process.env.REACT_APP_BASE_URL;

const Header = ({ selectedBoard, boards, updateBoardTitle, deleteBoard }) => {


  const [isEditing, setIsEditing] = useState(false)
  const [title, setTitle] = useState('')
  const currentBoard = boards.find(board => board.id === selectedBoard);

  useEffect(() => {
    setTitle(currentBoard?.title || '');
  }, [currentBoard]);


  const toggleEditMode = () => {
    setIsEditing(!isEditing);

    if (isEditing) {
      handleSubmit(currentBoard.id)
    }
  };

  const handleKeyDown = (e, boardId, newTitle) => {
    if (e.key === 'Enter') {
      setIsEditing(false);

      fetch(`${url}/tableros/put/titulo/${boardId}`, {
        method: 'PUT',
        headers: {
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ titulo: newTitle })
      })
        .then(response => response.json())
        
    }
  }

  const handleSubmit = (boardId) => {
    fetch(`${url}/tableros/put/titulo/${boardId}`, {
      method: 'PUT',
      headers: {
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ titulo: title })
    })
      .then(response => response.json())
      
  }

  const statusOptions = [
    { value: 'Pendiente', db: 0, color: 'bg-yellow-500' },
    { value: 'En progreso', db: 1, color: 'bg-orange-500' },
    { value: 'Hecho', db: 2, color: 'bg-green-500' },
  ]

  const priorityOptions = [
    { value: 'Alto', db: 3, color: 'bg-red-500' },
    { value: 'Medio', db: 2, color: 'bg-yellow-500' },
    { value: 'Bajo', db: 1, color: 'bg-blue-500' },
  ]
  const formatDate = (dateString) => {
    if (!dateString) return ''; // Manejar casos donde dateString es null o undefined
    return dateString.split('T')[0];
  }


  const handleDownloadBoard = (board) => {

    // Crea un nuevo libro de trabajo
    const workbook = XLSX.utils.book_new();

    // Itera sobre los procesos del board
    board.processes.forEach(process => {
      // Define el encabezado
      const header = [
        ["Título", "Estado", "Persona", "Fecha Inicio", "Fecha Término", "Prioridad", "Imagen", "Ubicación"]
      ];

      // Mapea las tareas del proceso para que cada una sea una fila en la hoja de trabajo
      const rows = process.tasks.map(task => [
        task.task || task.detalle,
        statusOptions.find(status => status.db === task.status)?.value,
        task.person || task.empleado,
        formatDate(task.startDate || task.fecha),
        formatDate(task.endDate),
        priorityOptions.find(priority => priority.db === task.priority)?.value,
        task.url || "Sin imagen", // Asumiendo que hay una propiedad image
        task.ubicacion || "Sin ubicación", // Asumiendo que hay una propiedad location
      ]);

      // Combina el encabezado y las filas
      const data = header.concat(rows);

      // Crea una hoja de trabajo a partir de los datos
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      // Agrega la hoja de trabajo al libro de trabajo
      XLSX.utils.book_append_sheet(workbook, worksheet, process.title);
    });

    // Genera un archivo Excel
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Crea un Blob a partir del buffer
    const file = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Crea un enlace para descargar el archivo
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = `${board.title}.xlsx`;
    document.body.appendChild(element); // Requerido para que funcione en Firefox
    element.click();
    document.body.removeChild(element); // Limpia el DOM
  };


  return (
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center space-x-2">
        {isEditing ? (
          <input
            id="board-title"
            type="text"
            value={currentBoard.title}
            onChange={(e) => updateBoardTitle(currentBoard.id, e.target.value)}
            onBlur={() => toggleEditMode(currentBoard.id)}
            onKeyDown={(e) => handleKeyDown(e, currentBoard.id, e.target.value)}
            className="w-full text-2xl font-bold bg-transparent border-b-2 border-gray-300 focus:border-blue-500 focus:outline-none transition-colors duration-300 mt-12 md:mt-0"

          />
        ) : (
          <h2 className="text-2xl font-bold mt-12 md:mt-0">{currentBoard.title}</h2>
        )}
        <button
          className="text-gray-600 hover:text-gray-900 "
          onClick={() => toggleEditMode(currentBoard.id)}
        >
          {isEditing ? <Check className="w-4 h-4 mt-12 md:mt-0" /> : <Edit2 className="w-4 h-4 mt-12 md:mt-0" />}
        </button>
        <button
          className="text-red-600 hover:text-red-800 mt-12 md:mt-0"
          onClick={() => deleteBoard(currentBoard.id)}
        >
          <Trash2 className="w-4 h-4" />
        </button>
        <button
          className="text-gray-600 hover:text-gray-900 mt-12 md:mt-0"
          onClick={() => handleDownloadBoard(currentBoard)}
          title="Descargar Excel"
        >
          <Download className="w-4 h-4" />
        </button>
      </div>
      <div className="w-30 h-20 overflow-hidden">
        <img src={Logo} alt="MIIDO" className="w-full h-full object-contain" />
      </div>
    </div>
  )
}

export default Header