// components/Navigation.js

import React from 'react'

const Navigation = ({ view, setView }) => {
  return (
    <div className="flex space-x-4 mb-4">
      <button 
        className={`px-3 py-1 text-base rounded transition-all duration-300 ${
          view === 'main' 
            ? 'bg-[#355D9C] text-white border-2 border-[#2C4A7A]' 
            : 'bg-gray-200 text-gray-800 hover:bg-gray-300 hover:text-gray-900'
        }`}
        onClick={() => setView('main')}
      >
        Tabla Principal
      </button>
      <button 
        className={`px-3 py-1 text-base rounded transition-all duration-300 ${
          view === 'gantt' 
            ? 'bg-[#355D9C] text-white border-2 border-[#2C4A7A]' 
            : 'bg-gray-200 text-gray-800 hover:bg-gray-300 hover:text-gray-900'
        }`}
        onClick={() => setView('gantt')}
      >
        Carta Gantt
      </button>
      <button 
        className={`px-3 py-1 text-base rounded transition-all duration-300 ${
          view === 'empresa' 
            ? 'bg-[#355D9C] text-white border-2 border-[#2C4A7A]' 
            : 'bg-gray-200 text-gray-800 hover:bg-gray-300 hover:text-gray-900'
        }`}
        onClick={() => setView('empresa')}
      >
        Empresa
      </button>
      {/* <button 
        className={`px-3 py-1 text-base rounded transition-all duration-300 ${
          view === 'recordatorios' 
            ? 'bg-[#355D9C] text-white border-2 border-[#2C4A7A]' 
            : 'bg-gray-200 text-gray-800 hover:bg-gray-300 hover:text-gray-900'
        }`}
        onClick={() => setView('recordatorios')}
      >
        Recordatorios
      </button> */}
    </div>
  )
}

export default Navigation
